import React, { useState } from 'react';
import { Col, Fade } from 'react-bootstrap';
import styles from "./Icons.module.css"
import icon from "../../images/home-icon-2.png";

const Logistics = () => {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  return (
    <Col
      xs={10}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      className='text-center py-1 position-relative'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={icon}
        alt="logo"
        placeholder='blurred'
        className={"rounded " + styles.Icon + " " + styles.DarkGreyImage}
      />

      <Fade in={show}>
        <div className={"rounded fs-3 text-white position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center justify-content-around " + styles.Text} >
          <span>Logistics & supply chains</span>
        </div>
      </Fade>
    </Col>
  );
};

export default Logistics;