import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import  styles from './Job.module.css';
import 'animate.css';

const Job = ({ title, requirments }) => {
  const [showFront, setShowFront] = useState(true);

  const handleMouseEnter = () => {
    setShowFront(false);
  };

  const handleMouseLeave = () => {
    setShowFront(true);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {
        showFront ? (
          <Card
            className={"mx-auto w-75 p-2 " + styles.Card}>
            <Card.Body className='d-flex align-items-center justify-content-center'>
              <span className='fw-bold fs-1 m-0 text-white'>{title}</span>
            </Card.Body>
          </Card >
        ) : (
          <Card
            className={"animate__animated animate__flipInY mx-auto w-75 p-1 d-flex flex-column justify-content-around  overflow-auto " + styles.Card}
          >
            <div className='fs-5 text-white'>
              We are looking for a {title} to join our team!
            </div>
            <div className='text-white text-start'>
              If you have:
              <ul className='text-white text-start'>
                {requirments.map(req => (
                  <li key={req}>{req}</li>
                ))}
              </ul>
            </div>
            <div className='text-white fs-5'>
              You might be a good fit!
            </div>
          </Card>
        )
      }
    </div>
  );
};

export default Job;

