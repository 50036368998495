import React from 'react';
import Heading from '../components/Heading';
import { Row, Col } from 'react-bootstrap';
import { colors } from '../constants/index';
import styles from "../styles/home.module.css"
import Guy from '../components/Guy.jsx';
import Rotem from '../components/Rotem';
import Amir from '../components/Amir';
import Frenkel from '../components/Frenkel';
import Vr from '../components/Icons/Vr';
import Logistics from '../components/Icons/Logistics';
import Industry from '../components/Icons/Industry';
import Academy from '../components/Icons/Academy';
import Military from '../components/Icons/Military';
import Aerospace from '../components/Icons/Aerospace';
import Drones from '../components/Icons/Drones';
import Ai from '../components/Icons/Ai';
import Climate from '../components/Icons/Climate';
import Disasters from '../components/Icons/Disasters';
import Cities from '../components/Icons/Cities';
import Responders from '../components/Icons/Responders';
import tlv from "../images/tel-aviv2.jpg";
import building from "../images/home-building.png";
import cloud from "../images/home-cloud.png";

const HomePage = () => {
  return (
    <div className={styles.Main}>
      <Row className={'m-0 px-2 py-5 w-100 ' + styles.Cyan}>
        <Col
          xs={10}
          md={8}
          className='fs-4 py-3 px-5 text-white bg-transparent d-flex justify-content-evenly flex-column order-md-2'
        >
          <Heading
            size="1"
            variant="white"
          >
            <b>
              About Asgard Cloud
            </b>
          </Heading>
          <br />
          <span className='text-black'>
            Asgard Cloud is a subsidiary of the Asgard System Group. The company is located in the Sarona complex in Tel Aviv and employs engineers, researchers, algorithm programmers, product managers, academics and industry experts
          </span>
          <br />
          <span className='text-black'>
            The company specializes in climate technology with an emphasis on AI, image processing, Data fusion, GIS applications, simulations, optimization and MR/XR for the benefit of smart and efficient technological dealing with the global climate crisis and climate disasters
          </span>
        </Col>
      </Row>
      <div className={styles.ImageDiv}>
        <img
          src={tlv}
          alt="logo"
          loading='lazy'
          className={styles.UpperImg}
        />
        <div className={styles.UpperDiv}></div>
        <div className={styles.LowerDiv}></div>
      </div>
      <Row className={'m-0 px-2 pt-5 w-100 d-flex justify-content-evenly ' + styles.LightGrey}>
        <Col
          xs={10}
          md={5}
          className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
        >
          <Heading
            size="1"
            variant={colors.blue}
          >
            <b>
              Management
            </b>
          </Heading>
        </Col>
        <Col
          xs={10}
          md={5}
          className="d-flex justify-content-center align-items-center bg-transparent"
        ></Col>
      </Row>
      <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-center ' + styles.LightGrey}>
        <Rotem />
        <Guy />
        {/* <Amir /> */}
        <Frenkel />
      </Row>
      <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Grey}>
        <Col
          xs={10}
          md={5}
          className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
        >
          <Heading
            size="2"
            variant={colors.lightBlue}
          >
            <b>
              Our Mission
            </b>
          </Heading>
          <span>
            Asgard Cloud was established in 2021, to develop advanced high technology to help first responders, government bodies, research, academy, and industry innocate correctly in tackling climate disasters and the global climate crisis
          </span>
        </Col>
        <Col
          xs={10}
          md={5}
          className="d-flex justify-content-center align-items-center bg-transparent"
        >
          <img
            src={building}
            alt="logo"
            loading='lazy'
            className={styles.Img}
          />
        </Col>
      </Row>
      <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Cyan}>
        <Col
          xs={10}
          md={5}
          className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column order-md-2'
        >
          <Heading
            size="1"
            variant="white"
          >
            <b>
              Our Vision
            </b>
          </Heading>
          <span className='text-black'>
            Asgard Cloud's system vision is to position itself at the forefront and technological of the future war in the global climate crisis
          </span>
          <br />
          <span className='text-black'>
            Through the application of diverse artificial intelligence and software solutions to properly deal with global climate crisis
          </span>
        </Col>
        <Col
          xs={10}
          md={5}
          className="d-flex justify-content-center align-items-center bg-transparent"
        >
          <img
            src={cloud}
            alt="logo"
            loading='lazy'
            className={styles.Img}
          />
        </Col>
      </Row>
      <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Black}>
        <Col
          xs={10}
          md={8}
          className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
        >
          <Heading
            size="2"
            variant={colors.lightBlue}
          >
            <b>
              Our Market Sectors
            </b>
          </Heading>
        </Col>
        <Col md={2}></Col>
      </Row>
      <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Black}>
        <Vr />
        <Logistics />
        <Industry />
        <Academy />
        <Military />
        <Aerospace />
        <Drones />
        <Ai />
        <Climate />
        <Disasters />
        <Cities />
        <Responders />
      </Row>
    </div >
  );
};

export default HomePage;
