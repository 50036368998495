import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './Footer.module.css';
import Insta from "../../images/linkedin.png";
import facebook from "../../images/facebook.png";
import linkedin from "../../images/instagram.png";
import mail from "../../images/mail.png";
import pin from "../../images/pin.png";
import Logo from "../../images/cloud-logo.png";

const Footer = () => {

    const handleIconClick = url => {
        window.open(url, "_blank");
    };

    return (
        <Row className={'bg-dark w-100 py-2 m-0 text-white text-center ' + styles.Footer}>
            <Col
                md={4}
                xs={10}
                className='my-md-auto my-2 mx-auto'>
                <img
                    src={Logo}
                    alt="logo"
                    placeholder='blurred'
                    width={178}
                    height={67}
                />
            </Col>
            <Col
                md={4}
                xs={10}
                className='my-md-auto my-2 mx-auto order-md-3'>
                <img
                    src={Insta}
                    alt="logo"
                    placeholder='blurred'
                    width={40}
                    height={40}
                    className={styles.Image}
                    onClick={() => handleIconClick("https://www.linkedin.com/company/asgard-systems-ltd")}
                />
                <img
                    src={facebook}
                    alt="logo"
                    placeholder='blurred'
                    width={40}
                    height={40}
                    className={styles.Image}
                    onClick={() => handleIconClick("https://www.facebook.com/asgardsys")}
                />
                <img
                    src={linkedin}
                    alt="logo"
                    placeholder='blurred'
                    width={40}
                    height={40}
                    className={styles.Image}
                    onClick={() => handleIconClick("https://www.instagram.com/asgardsys")}
                />
                <img
                    src={mail}
                    alt="logo"
                    placeholder='blurred'
                    width={40}
                    height={40}
                    className={styles.Image}
                />
                <img
                    src={pin}
                    alt="logo"
                    placeholder='blurred'
                    width={40}
                    height={40}
                    className={styles.Image}
                />
            </Col>
            <Col
                md={4}
                xs={10}
                className='my-md-auto my-2 mx-auto order-md-2'
            >
                <span className='text-white fs-5'>© 2022 Asgard Cloud. All rights reserved.</span>
            </Col>
        </Row >
    );
};

export default Footer;

