import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import styles from './header.module.css';
import logo from "../../images/cloud-logo.png"

const LINKS = [
    {
        id: '0',
        route: '/',
        label: 'About'
    },
    {
        id: '1',
        route: '/technology',
        label: 'Our Technology'
    },
    {
        id: "2",
        route: '/cases',
        label: "Case Studies"
    },
    {
        id: "3",
        route: '/careers',
        label: 'Careers'
    },
    {
        id: "4",
        route: '/contact',
        label: 'Contact Us'
    }
];

const Header = ({ width }) => {
    const [show, setShow] = useState(false);

    return (
        <Navbar
            bg="dark"
            variant='dark'
            expand="md"
            sticky='top'
            collapseOnSelect
        >
            <Container fluid>
                {width <= 768 ? (
                    <Navbar.Brand>
                        <img
                            src={logo}
                            alt="logo"
                            width={120}
                            height={45}
                        />
                    </Navbar.Brand>
                ) : (
                    <Nav className={"justify-content-center flex-grow-1 pe-3 fs-5 " + styles.links}>
                        {LINKS.map(({ id, route, label }) => (
                            <NavLink
                                key={id}
                                to={route}
                                style={({ isActive }) => ({
                                    color: isActive ? '#66a9d6' : 'white'
                                })}
                            >{label}</NavLink>
                        ))}
                    </Nav>
                )}
                <Navbar.Toggle
                    aria-controls="offcanvasNavbar"
                    onClick={() => setShow(true)}
                />
                <Navbar.Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="start"
                    show={show}
                >
                    <Offcanvas.Header onClick={() => setShow(false)} closeButton>
                        <Offcanvas.Title className='text-white' id="offcanvasNavbarLabel">Asgard Cloud</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className={"justify-content-end flex-grow-1 pe-3 fs-5 " + styles.links}>
                            {LINKS.map(({ id, route, label }) => (
                                <NavLink
                                    key={id}
                                    to={route}
                                    style={({ isActive }) => ({
                                        color: isActive ? '#66a9d6' : 'white'
                                    })}
                                    onClick={() => setShow(false)}>{label}</NavLink>
                            ))}
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
};



export default Header;
