import { useState, useEffect } from "react";

const useScreenWidth = () => {
    const hasWindow = typeof window !== 'undefined';

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (!hasWindow) {
            return;
        }

        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [hasWindow]);

    return screenWidth;
};

export default useScreenWidth;