import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import styles from '../styles/manager.module.css';
import guy from "../images/guy.png";

const Guy = () => {
    const [show, setShow] = useState(false);

    const handleMouseEnter = () => setShow(true);
    const handleMouseLeave = () => setShow(false);

    return (
        <Col
            xs={11}
            md={5}
            lg={3}
            xl={2}
            className='fs-4 py-3 text-white bg-transparent d-flex justify-content-center position-relative'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img
                src={guy}
                alt="logo"
                placeholder='blurred'
                className={styles.AvatarImg}
                loading="eager"
            />
            <Fade in={show}>
                <div className={"overflow-auto position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center justify-content-around " + styles.Info} >
                    <span>Guy Avidan</span>
                    <span>VP R&D</span>
                    <ul className='fs-6'>
                        <li>7 Years of technology managment</li>
                        <li>AI & Algo-tech expert</li>
                        <li>BSc in Computers science from Reichman University (IDC)</li>
                    </ul>
                </div>
            </Fade>
        </Col>
    );
};

export default Guy;