import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Job from '../components/Job/Job';
import styles from "../styles/careers.module.css";

const DUMMY_DATA = [
    {
        id: 0,
        title: "Full Stack Developer",
        requirments: [
            "1-2 Years as a software developer.",
            "experience with HTML, CSS & JavaScript.",
            "Experience with React.js.",
            "Experience with Node.js.",
            "Experience with Python - an advantage!"
        ]
    },
    {
        id: 1,
        title: "Full Stack Developer",
        requirments: [
            "1-2 Years as a software developer.",
            "experience with HTML, CSS & JavaScript.",
            "Experience with React.js.",
            "Experience with Node.js.",
            "Experience with Python - an advantage!"
        ]
    },
    {
        id: 2,
        title: "Full Stack Developer",
        requirments: [
            "1-2 Years as a software developer.",
            "experience with HTML, CSS & JavaScript.",
            "Experience with React.js.",
            "Experience with Node.js.",
            "Experience with Python - an advantage!"
        ]
    },
    {
        id: 3,
        title: "Full Stack Developer",
        requirments: [
            "1-2 Years as a software developer.",
            "experience with HTML, CSS & JavaScript.",
            "Experience with React.js.",
            "Experience with Node.js.",
            "Experience with Python - an advantage!"
        ]
    }
];

const CareersPage = () => {
    return (
        <div>
            <div className={"p-4 d-flex justify-content-center align-items-center w-100 " + styles.TopDiv}>
                <Col
                    lg={6}
                    md={8}
                    xs={10}
                    className={"text-white text-center fs-4 px-3 py-1 rounded d-flex justify-content-around flex-column " + styles.Message}>
                    <span>
                        We at Asgard Cloud are looking for the next generation of employees, without gender, race, religous beliefs, or any discrimination at all!
                    </span>
                    <span>
                        We are waiting for you, come prepared.
                    </span>
                    <span>
                        Send your CV to
                        <br />
                        <b>
                            jobs@asgardsys.co.il
                        </b>
                    </span>
                </Col>
            </div>
            <Row className={'mx-0 text-center p-2 flex-grow-1 ' + styles.JobList}>
                {DUMMY_DATA.map(data => (
                    <Col
                        key={data.id}
                        xs={12}
                        sm={10}
                        md={6}
                        xl={3}
                        className='mx-auto p-0 my-2'
                    >
                        <Job
                            title={data.title}
                            requirments={data.requirments}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default CareersPage;
