import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from '../styles/contact.module.css'

const ContactUs = () => {
    return (
        <Row className={'m-0 w-100 d-flex justify-content-center align-items-center ' + styles.Back}>
            <Col
                className={'text-white rounded text-center p-3 fs-3 ' + styles.Note}
                xs={10}
                md={6}
                lg={5}
            >
                <span>
                    For Any Inquiries Please Email
                </span>
                <span>
                    info@asgardsys.co.il
                </span>
                <span>
                    Or just contact us on social media!
                </span>
            </Col>
        </Row>
    );
};

export default ContactUs;