import React from 'react';
import Heading from '../components/Heading';
import { Col, Container, Row } from 'react-bootstrap';
import { colors } from '../constants/index';
import styles from '../styles/technology.module.css';
import stages from "../images/technology-stages.png";
import ai from "../images/technology-ai.png";
import dl from "../images/technology-dl.png";
import nn from "../images/technology-nn.png";
import ml from "../images/technology-ml.png";
import david from "../images/technology-david.png";
import layers from "../images/technology-layers.png";
import gis from "../images/technology-gis.png";
import practicum from "../images/technology-practicum.jpg";

const Technology = () => {
    return (
        <>
            <Row className={'m-0 px-2 py-3 w-100 d-flex justify-content-evenly bg-dark'}>
                <Col className="d-flex justify-content-center align-items-center bg-transparent">
                    <img
                        src={stages}
                        alt="logo"
                        placeholder='blurred'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            {/* ROW WITH 4 ICONS */}
            <Container fluid className={"w-100 p-0 " + styles.Black}>
                <Row className={'m-0 w-100 px-2 py-5'}>
                    <Col
                        xs={10}
                        md={8}
                        className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
                    >
                        <Heading
                            size="1"
                            variant={colors.lightBlue}
                        >
                            <b>
                                The AI Revolution
                            </b>
                        </Heading>
                        <span className='text-white'>
                            There are quite a few companies that deal with climate. our X Factor is the use advanced and innovative AI algorithms and machine learning for the needs of our product loens and projects in the field of climate engineering.
                        </span>
                    </Col>
                </Row>
                <Row className={`m-0 w-100 h-100 py-5 d-flex justify-content-center`}>
                    <Col
                        xs={11}
                        sm={5}
                        md={3}
                        className="p-0 d-flex justify-content-center align-items-center bg-transparent">
                        <img
                            src={ai}
                            alt="deep learning"
                            placeholder='blurred'
                            loading='lazy'
                        />
                    </Col>
                    <Col
                        xs={11}
                        sm={5}
                        md={3}
                        className="p-0 d-flex justify-content-center align-items-center bg-transparent">
                        <img
                            src={dl}
                            alt="deep learning"
                            placeholder='blurred'
                        />
                    </Col>
                    <Col
                        xs={11}
                        sm={5}
                        md={3}
                        className="p-0 d-flex justify-content-center align-items-center bg-transparent">
                        <img
                            src={nn}
                            alt="deep learning"
                            loading='lazy'
                        // className={styles.Img}
                        />
                    </Col>
                    <Col
                        xs={11}
                        sm={5}
                        md={3}
                        className="p-0 d-flex justify-content-center align-items-center bg-transparent">
                        <img
                            src={ml}
                            alt="deep learning"
                            placeholder='blurred'
                        />
                    </Col>
                </Row>
            </Container >
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Cyan}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
                >
                    <Heading
                        size="1"
                        variant="white"
                    >
                        <b>
                            Unique Image processing specialty
                        </b>
                    </Heading>
                    <span className='text-black'>
                        Our researchers and software developers apply advanced image processing algorithms in our product lines and projects with an emphasis on satellite imagery, video, and drone applications and GIS applications by seasons.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        src={david}
                        alt="logo"
                        loading='lazy'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Grey}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column order-md-2'
                >
                    <Heading
                        size="1"
                        variant={"black"}
                    >
                        <b>
                            All about Data and Data Fusion
                        </b>
                    </Heading>
                    <span>
                        We at the company belive in the Data Revolution and Digital Transformation and therefore implement advanced models of Data Fusion, Data Mining, and Big Data in all of our systems and products.
                    </span>
                    <span>
                        We rely on atmospheric, meteorological, environmental information, climate, studies from the academy and industry, R&D, image and satellite imagery, and space history - all of which we process in our Data engines and produce bottom lines of outputs.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        src={layers}
                        alt="logo"
                        loading='lazy'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Black}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
                >
                    <Heading
                        size="1"
                        variant={colors.lightBlue}
                    >
                        <b>
                            GIS for general use
                        </b>
                    </Heading>
                    <span>
                        Asgard Cloud develops its applications and products on diverse GIS infrastructures
                    </span>
                    <span>
                        The company's product shows physical and infographic vector markers and Data on GIS maps for the use of system by the operational or research user.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        src={gis}
                        alt="logo"
                        loading='lazy'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.LightGrey}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column order-md-2'
                >
                    <Heading
                        size="1"
                        variant={"white"}
                    >
                        <b>
                            Climate research
                        </b>
                    </Heading>
                    <span className='text-black'>
                        Our company conducts research collaboration with leading academia and climate & environmental research departments in Israel and around the world.
                    </span>
                    <br />
                    <span className='text-black'>
                        The scientists and researches at the company in collaboration with researchers around the world, perfect the models and outputs that make up the living engine of our products.
                    </span>
                    <br />
                    <span className='text-black'>
                        In addition, we provide advice and scientific research reports to many industries and academia, and government departments for assistance in future analysis and proper response to the global climate crisis and preparedness for expected climate disasters.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        src={practicum}
                        alt="logo"
                        loading='lazy'
                        className={styles.Img}
                    />
                </Col>
            </Row>
        </>
    )
};

export default Technology;
