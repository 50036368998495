import React from 'react';
import styles from './LogoBar.module.css';
import Logo from "../../images/cloud-logo.png";

const LogoBar = () => {
    return (
        <div className={styles.Bar}>
            <img
                src={Logo}
                alt="logo"
                width={178}
                height={67}
                loading='lazy'
            />
        </div>
    );
};

export default LogoBar;
