import React from 'react';

const Heading = ({ children, variant, size }) => {
    const sizesDictionary = {
        "1": "fs-1",
        "2": "fs-2",
        "3": "fs-3",
        "4": "fs-4",
        "5": "fs-5",
    };

    const headingStyle = {
        color: variant
    };

    return (
        <span
            className={sizesDictionary[size]}
            style={headingStyle}
        >
            {children}
        </span>
    );
};

export default Heading;
