import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useScreenWidth from './hooks/useScreenWidth';
import Home from './pages/Home';
import Technology from './pages/Technology';
import Cases from './pages/Cases';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import LogoBar from './components/LogoBar/LogoBar';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

const App = () => {
  const width = useScreenWidth();

  return (
    <Router>
      <div className="app-layout">
        {width > 768 && <LogoBar />}
        <Header width={width} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cases" element={<Cases />} />
          <Route path="/technology" element={<Technology />} />
        </Routes>
        <Footer />
      </div>
    </Router >
  );
};

export default App;
