import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import styles from '../styles/manager.module.css';
import rotem from "../images/rotem.png";

const Rotem = () => {
    const [show, setShow] = useState(false);

    const handleMouseEnter = () => setShow(true);
    const handleMouseLeave = () => setShow(false);

    return (
        <Col
            xs={11}
            md={5}
            lg={3}
            xl={2}
            className='fs-4 py-3 text-white bg-transparent d-flex justify-content-center position-relative'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img
                src={rotem}
                alt="logo"
                loading='eager'
                className={styles.AvatarImg}
            />
            <Fade in={show}>
                <div className={"overflow-auto position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center justify-content-around " + styles.Info} >
                    <span>Rotem Mey-Tal</span>
                    <span>CEO</span>
                    <ul className='fs-6'>
                        <li>15 Years of technology managment</li>
                        <li>BSc in Mechanical & Systems Engineering</li>
                        <li>MBA from Ben-Gurion University</li>
                    </ul>
                </div>
            </Fade>
        </Col>
    );
}

export default Rotem