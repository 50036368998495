import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Heading from '../components/Heading';
import styles from '../styles/cases.module.css';
import { colors } from '../constants/index'
import case1 from "../images/case-studies-05.png";
import case2 from "../images/case-studies-01.png";
import case3 from "../images/case-studies-06.png";
import case4 from "../images/case-studies-02.png";
import case5 from "../images/case-studies-08.jpg";
// import case6 from "../images/uae.png";

const UseCases = () => {
    return (
        <div>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Black}>
                <Col
                    xs={10}
                    md={8}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
                >
                    <Heading
                        size="2"
                        variant={colors.lightBlue}
                    >
                        <b>
                            Wild Fire spread
                        </b>
                    </Heading>
                    <br />
                    <span>
                        Asgard Cloud has developed a WEB system for predicting wildfires in forests, agricultural fields and populated areas for the benefit of First responders and government deparments that make real-time and future decisions on national emergencies
                    </span>
                    <br />
                    <span>
                        The System was a hugh success in the extreme fire event in the forests of Jerusalem, Israel, at August 2021.
                    </span>
                    <br />
                    <span>
                        The R&D team if the company Deployed in the command room of the fire brigade in the Jerusalem mountaines, and assisted commander and officers in managing the incident of the extreme fire.
                    </span>
                </Col>
                <Col md={2}></Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Cyan}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
                >
                    <Heading
                        size="1"
                        variant="white"
                    >
                        <b>
                            Urban Floods
                        </b>
                    </Heading>
                    <span className='text-black'>
                        Asgard Cloud develops a Web system and desktop app, for predictiong urban disasters of urban flooding in cities and villages using advanced models, rythem algorithms, and urban GIS maps, roads, and intercity railways.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        src={case1}
                        alt="logo"
                        loading='lazy'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Grey}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column order-md-2'
                >
                    <Heading
                        size="2"
                        variant={"black"}
                    >
                        <b>
                            Climate Forecasting
                        </b>
                    </Heading>
                    <span>
                        Asgard Cloud specializes in forecasting future climates, even for the long terms of years ahead, and thus can help research departments, academia, consulting and insurance correctly assess climate hazards on geographical or industrial areas and a logistics supply chain.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        src={case2}
                        alt="logo"
                        loading='lazy'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Black}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
                >
                    <Heading
                        size="2"
                        variant={colors.lightBlue}
                    >
                        <b>
                            Climate and Drones
                        </b>
                    </Heading>
                    <span>
                        The field of unmanned aerial vehicles with and emphasis on Drones for delivery, military and logistics purposes depends entirely on precipitation, weather, and winds.
                    </span>
                    <br />
                    <span>
                        The weather affects the success of the mission, the energy consumption of the drone, and the video capability of the drone camera and payload.
                    </span>
                    <br />
                    <span>
                        Agard Cloud has developed in collaboration with DDR & D (MAFAT) and IIA an application for wind and climate intelligence for UAVs and Drones. The system is suitable for civilian, industrial, military and HLS needs.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        src={case3}
                        alt="logo"
                        loading='lazy'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.LightGrey}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column order-md-2'
                >
                    <Heading
                        size="1"
                        variant={"white"}
                    >
                        <b>
                            Smart Agriculture Coming soon
                        </b>
                    </Heading>
                    <span className='text-white'>
                        The Asgard Cloud system is developing a future interface for climate intelligence for smart agriculture.
                    </span>
                    <br />
                    <span className='text-white'>
                        The need for future forecasting of precipitation and rainfall for agriculture as well as drought and flooding is critical for many crops and future agriculture.
                    </span>
                    <br />
                    <span className='text-white'>
                        In the current and modern era where agriculture is monitored by advanced IoT sensors - the need for weather intelligence is a must.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        src={case4}
                        alt="logo"
                        loading='lazy'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Cyan}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column'
                >
                    <Heading
                        size="1"
                        variant="white"
                    >
                        <b>
                            Emission dispersion Coming soon
                        </b>
                    </Heading>
                    <span className='text-black'>
                        The Asgard Cloud system is developing a future interface for climate intelligence for emmisions of gases and pollutants.
                    </span>
                    <br />
                    <span className='text-black'>
                        One of the critical problems of the industry is emissions of pollutants and gases and is the number one cause of the gobal climate crisis.
                        Many countries enforce laws and regulate the issue.
                    </span>
                    <br />
                    <span className='text-black'>
                        The system with the future interface will be able to predict the spread of gases and pollutants and hazadous substances in the air and space, warn and predict trends of pollutuin and build an immediate picture of the issue.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    <img
                        loading='lazy'
                        src={case5}
                        alt="logo"
                        placeholder='blurred'
                        className={styles.Img}
                    />
                </Col>
            </Row>
            <Row className={'m-0 px-2 py-5 w-100 d-flex justify-content-evenly ' + styles.Grey}>
                <Col
                    xs={10}
                    md={5}
                    className='fs-4 py-3 text-white bg-transparent d-flex justify-content-evenly flex-column order-md-2'
                >
                    <Heading
                        size="2"
                        variant={"black"}
                    >
                        <b>
                            Dust & Sand storms forecasting
                        </b>
                    </Heading>
                    <br />
                    <span>
                        Recent studies show that warming planet will make Dust storms more intense in the Middle east
                        Also Global warming could lead to more frequent and ferocious Dust storms in years to come. So Climate change is expected to lead to more severe dust storms in the planet.
                    </span>
                    <br />
                    <span>
                        Asgard Cloud's solution for forecasting Sand and Dust storms using Data fusion and AI technology
                        Is an effective tool for countries and industries suffering from this climate change challenge
                        Especially in the Middle East.
                    </span>
                </Col>
                <Col
                    xs={10}
                    md={5}
                    className="d-flex justify-content-center align-items-center bg-transparent"
                >
                    {/* REMOVE LOGO OF UAE */}
                    {/* <img
                        src={case6}
                        alt="logo"
                        placeholder='blurred'
                        aspectRatio
                        className={styles.Img}
                    /> */}
                </Col>
            </Row>
        </div >
    );
};

export default UseCases;